(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("_"));
	else if(typeof define === 'function' && define.amd)
		define("@wix/forms-viewer", ["_"], factory);
	else if(typeof exports === 'object')
		exports["@wix/forms-viewer"] = factory(require("_"));
	else
		root["@wix/forms-viewer"] = factory(root["_"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__215__) {
return 